import momentTZ from 'moment-timezone';
import { getUnixTime, format } from 'date-fns';

export const DateRangeLabels = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    LAST7DAYS: 'Last7Days',
    LAST30DAYS: 'Last30Days',
    LAST3MONTHS: 'Last3Months',
    LAST6MONTHS: 'Last6Months',
    CUSTOM: 'Custom',
  };

export const calculateDatesBasedOnUsersTimezone = (dateRangeValue, startDate, endDate) => {
  // current utc time
  const currentTimeOfToday = momentTZ.utc().toDate();
  // user start of day and end of start day
  const timeZone = momentTZ.tz.guess(); // Assuming you want to use the user's timezone
  const startOfDay = momentTZ().tz(timeZone).startOf('day').utc();
  const endOfDay = momentTZ().tz(timeZone).endOf('day').utc();

  switch (dateRangeValue) {
    case DateRangeLabels.TODAY:
    case DateRangeLabels.TODAY.toLowerCase():{
      const startOfDayDate = startOfDay.toDate();
      return {
        dateRange: dateRangeValue,
        dates: [startOfDayDate, currentTimeOfToday],
        timestamps: [
          getUnixTime(startOfDayDate),
          getUnixTime(currentTimeOfToday),
        ],
      };
    }
    case DateRangeLabels.YESTERDAY:
    case DateRangeLabels.YESTERDAY.toLowerCase():{
      const fromStartOfTomorrow = startOfDay.add(-1, 'day').toDate();
      const toEndOfTomorrow = endOfDay.add(-1, 'day').toDate();
      return {
        dateRange: dateRangeValue,
        dates: [fromStartOfTomorrow, toEndOfTomorrow],
        timestamps: [
          getUnixTime(fromStartOfTomorrow),
          getUnixTime(toEndOfTomorrow),
        ],
      };
    }
    case DateRangeLabels.LAST7DAYS:
    case DateRangeLabels.LAST7DAYS.toLowerCase():{
      const fromStartOfLast7Days = startOfDay.add(-6, 'day').toDate();
      return {
        dateRange: dateRangeValue,
        dates: [fromStartOfLast7Days, currentTimeOfToday],
        timestamps: [
          getUnixTime(fromStartOfLast7Days),
          getUnixTime(currentTimeOfToday),
        ],
      };
    }
    case DateRangeLabels.LAST30DAYS:
    case DateRangeLabels.LAST30DAYS.toLowerCase():{
      const fromStartOfLast30Days = startOfDay.add(-29, 'day').toDate();
      return {
        dateRange: dateRangeValue,
        dates: [fromStartOfLast30Days, currentTimeOfToday],
        timestamps: [
          getUnixTime(fromStartOfLast30Days),
          getUnixTime(currentTimeOfToday),
        ],
      };
    }
    case DateRangeLabels.LAST3MONTHS:
    case DateRangeLabels.LAST3MONTHS.toLowerCase():{
      const fromStartOfLast3Months = startOfDay.add(-3, 'month').toDate();
      return {
        dateRange: dateRangeValue,
        dates: [fromStartOfLast3Months, currentTimeOfToday],
        timestamps: [
          getUnixTime(fromStartOfLast3Months),
          getUnixTime(currentTimeOfToday),
        ],
      };
    }
    case DateRangeLabels.LAST6MONTHS:
    case DateRangeLabels.LAST6MONTHS.toLowerCase():{
      const fromStartOfLast6Months = startOfDay.add(-6, 'month').toDate();
      return {
        dateRange: dateRangeValue,
        dates: [fromStartOfLast6Months, currentTimeOfToday],
        timestamps: [
          getUnixTime(fromStartOfLast6Months),
          getUnixTime(currentTimeOfToday),
        ],
      };
    }
    case DateRangeLabels.CUSTOM:
    case DateRangeLabels.CUSTOM.toLowerCase(): {
      const startUTC = momentTZ(startDate)
        .startOf('day')
        .tz(timeZone, true)
        .utc()
        .toDate();
      const endUTC = momentTZ(endDate).endOf('day').tz(timeZone, true).utc();
      const endDateToSend = momentTZ(endDate)
        .utc(true)
        .isSame(momentTZ.tz(timeZone).utc(true), 'day')
        ? currentTimeOfToday
        : endUTC.toDate();
      return {
        dateRange: dateRangeValue,
        dates: [startUTC, endDateToSend],
        timestamps: [getUnixTime(startUTC), getUnixTime(endDateToSend)],
      };
    }
    default:
      return {
        dateRange: dateRangeValue,
        dates: [startOfDay.toDate(), currentTimeOfToday],
        timestamps: [
          getUnixTime(startOfDay.toDate()),
          getUnixTime(currentTimeOfToday),
        ],
      };
  }
};

export const formatDateForUi = (date, dateFormat = 'dd MMM yyyy') => {
  const dateObject = new Date(date);
  return format(dateObject, dateFormat);
};