import { httpRequest, methods } from '../http';

const RTIAPIkeys = {
    PAGE_VIEWS: 'rti_page_views',
    VISITS_SUMMARY: 'rti_visits_summary',
    VISITS_INSIGHTS: 'rti_visits_insights',
    RTI_SETTINGS: 'rti_settings',
    CLIENT_INTERCEPTIONS: 'rti_client_side_interceptions_by_url',
    API_INTERCEPTIONS: 'rti_api_interceptions',
};

const getVisitsSummary = params => {
    const { response } = httpRequest({ method: methods.get, pathname: 'rti-dashboard/get_visits_summary', params });
    return response;
};

const getPageViews = params => {
    const { response } = httpRequest({ method: methods.get, pathname: 'rti-dashboard/get_pageviews_summary', params });
    return response;
};

const getRTISettings = () => {
    const { response } = httpRequest({ method: methods.get, pathname: 'rti-dashboard/get_rti_dashboard_settings' });
    return response;
};

const getVisitsInsights = params => {
    let paramsCopy = { ...params };
  if (
    typeof paramsCopy.dateRange === 'object' &&
    'startDate' in paramsCopy.dateRange &&
    'endDate' in paramsCopy.dateRange
  ) {
    paramsCopy.startDate = paramsCopy.dateRange.startDate;
    paramsCopy.endDate = paramsCopy.dateRange.endDate;
    const {dateRange,...paramsWithoutDateRange} = paramsCopy;
    paramsCopy = paramsWithoutDateRange;

  }
    const { response } = httpRequest({ method: methods.get, pathname: 'rti-dashboard/get_invalid_visit_type',params:paramsCopy });
    return response;
};

const getClientSideInterceptions = params => {
    const { response } = httpRequest({ method: methods.get, pathname: 'rti-dashboard/get_client_side_interceptions', params });
    return response;
};

const getApiInterceptions = params => {
    const { response } = httpRequest({ method: methods.get, pathname: 'rti-dashboard/get_api_interceptions', params });
    return response;
};

export { getVisitsSummary, getPageViews, RTIAPIkeys, getRTISettings, getVisitsInsights, getClientSideInterceptions, getApiInterceptions };
