import React from 'react';
import moment from"moment";
import Tooltip from '../../../tooltip';
import Edit from '../../../../assets/edit.svg';
import Pause from '../../../../assets/pause-circle.svg';
import Play from '../../../../assets/play-circle.svg';
import Remove from '../../../../assets/delete.svg';
import Failed from '../../../../assets/failed-triangle.svg';
import { getType, getFrequencyText } from "../utils";

import styles from '../../table.module.scss';

const Row = ({ cm, row, reportMethods }) => {
    // remove this line once scheduled cron job reports are implemented
    if (row?.cronJob) {
        return null;
    }
    //
    const { onActionHandler, onEditReport } = reportMethods;

    return (
        <div className={styles.row} data-test-selector="exportables-table-scheduler-row">
            <div className={styles.scheduled}>{row.name}</div>
            <div className={styles.scheduled}>{getType(cm, row.type)}</div>
            <div className={styles.scheduled}>{moment(row.createdAt).format('DD MMMM YYYY, hh:mm A')}</div>
            <div className={styles.scheduled}>{row.createdBy}</div>
            <div className={styles.scheduled}>{getFrequencyText(cm, row.frequency)}</div>
            <div className={`${styles.scheduled}`}>
                {!row.lastRun.includes('0001') ? moment(row.lastRun).format('DD MMMM YYYY, hh:mm A') : 'N/A'}
                {row.failedOnLastRun ? <Tooltip title={"Failed on last run"} component={ <img className={styles.failedIcon} src={Failed} alt="failed"/>}/> : <div className={styles.failedIcon}/>}
            </div>
            <div className={styles.scheduled}>
                <Tooltip title={"Edit report"} component={ <img src={Edit} alt="edit" onClick={() => onEditReport(row)} className={styles.icon}/>}/>
                {row.isRunning ?
                    <Tooltip title={"Pause report"} component={ <img src={Pause} alt="pause" onClick={() => onActionHandler('pause', row.id, row.name, row)} className={styles.icon}/>}/> :
                    <Tooltip title={"Resume report"} component={ <img src={Play} alt="plat" onClick={() => onActionHandler('resume', row.id, row.name, row)} className={styles.icon}/>}/>
                }
                <Tooltip title={"Delete report"} component={ <img src={Remove} alt="remove" onClick={() => onActionHandler('delete', row.id, row.name, row)} className={styles.icon}/>}/>
            </div>
            <div/>
        </div>
    );
};

export default Row;