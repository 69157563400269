import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AdobeAnalyticsConfigSchema, ClickTrueTagsState, TagsState, TagsWithAdobeConfig } from '../types';

export const useTagsWithAdobeConfig = () => {
    const { tags: clickTrueTags = [], isLoading } = useSelector<TagsState, ClickTrueTagsState>(state => state.clickTrueTags);

    const tagsWithAdobeConfig = useMemo(
        () =>
            clickTrueTags.reduce<TagsWithAdobeConfig>(
                (accumulator, current) => {
                    const item = {
                        label: current.name,
                        value: current.id,
                        selected: false,
                    };
                    try {
                        const { adobeAnalytics: adobeAnalyticsConfig }: { adobeAnalytics: AdobeAnalyticsConfigSchema } = JSON.parse(current.protectionConfig) || {};
                        //Currently, the assumption is that all tags share the same adobe config - will be changed in the future
                        if(adobeAnalyticsConfig){
                          accumulator.config = adobeAnalyticsConfig; 
                        }
                        item.selected = !!adobeAnalyticsConfig;
                        accumulator.tags.push(item);
                    } catch (error) {
                        console.error(error);
                    }
                    return accumulator;
                },
                { tags: [], config: false }
            ),
        [clickTrueTags]
    );

    return { tagsWithAdobeConfig, isLoading };
};
